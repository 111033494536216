import { useRouter } from 'next/router';
import { parsePageSeo } from 'helpers/utils/parsePageSeo';
import { NextSeo } from 'next-seo';
import StandardError404 from 'components/04-templates/404';
import { getClearPath } from 'helpers/utils/url/getClearPath';

const customMessagesMap = {
  default: "The page you're looking for wasn't found. Let's guide you back.",
  shorts:
    "The page you're looking for doesn't exist anymore. If you want to find Short films, go to the Homepage, Category Pages or Search."
} as const;

const Error404 = () => {
  const { asPath } = useRouter();

  const segmentKey = getClearPath(asPath).replace('/', '');

  const customMessage =
    customMessagesMap[segmentKey] || customMessagesMap.default;

  return (
    <>
      <NextSeo
        {...parsePageSeo({
          asPath,
          ogTitle: '404',
          title: 'Not found'
        })}
      />
      <StandardError404 message={customMessage} />
    </>
  );
};

export default Error404;
