import Box from 'components/01-atoms/Box';
import Heading from 'components/01-atoms/Heading';
import Button from 'components/02-molecules/Button';
import { routes } from 'lib/routes';
import Icon from 'components/01-atoms/Icon';

type Props = {
  message: string;
};

const Error404 = ({ message }: Props) => (
  <Box className="flex justify-center items-center w-full h-full min-h-[inherit] relative bg-page-404 bg-cover bg-center">
    <Box className="-mt-20 text-center px-4 narrow:px-10 max-w-3xl">
      <Heading
        classes="text-network-white narrow:text-8xl mb-4 narrow:mb-10"
        level={1}
        text="Oops!"
        variant="heading1"
      />
      <p
        className="text-network-white text-28 font-plus-jakarta-sans font-700 narrow:mb-5 mb-10"
        suppressHydrationWarning
      >
        {message}
      </p>
      <Button
        as="link"
        className="mx-auto my-0 inline-flex"
        href={routes.home()}
        icon={<Icon type="chevronLeft" />}
        size="lg"
        text="Back to Home"
        variant="contained"
      />
    </Box>
  </Box>
);

export default Error404;
